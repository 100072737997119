import React from "react"
import { Container, Main, Seo } from "../components"
import { H1, P } from "../elements"
import { graphql } from "gatsby"

const successPage = ({ location }) => {
  return (
    <Container>
      <Seo
        pagetitle="お問い合わせありがとうございます。"
        pagepath={location.pathname}
      />
      <Main>
        <H1
          style={{ padding: "20vh 0", fontSize: "1.5rem" }}
          textAlign="center"
        >
          お問い合わせありがとうございます。
        </H1>
        <P textAlign="center">info@marusue.jp よりご返信させて頂きます。</P>
      </Main>
    </Container>
  )
}

export const query = graphql`
  query {
    shop: file(relativePath: { eq: "shop.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default successPage
